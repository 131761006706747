import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { format } from 'date-fns';

import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useWebsite from '@/hooks/website-builder/useWebsite';
import useWebsiteMailingList from '@/hooks/website-builder/useWebsiteMailingList';
import { PromoteFlowQueryParams } from '@/models/Enums';
import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';
import { handleApiError } from '@/utility/api';
import { isDevelopment } from '@/utility/generic';

import Loading from '../utility/Loading';
import ButtonComponent from '../utility/microcomponents/Button';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import DialogModal from '../utility/modals/DialogModal';
import CampaignStatus from '../utility/statuses/CampaignStatus';

const CampaignsFanHub = () => {
  const [params, setSearchParams] = useSearchParams();

  const { accountId } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const { isSubscribed } = useSubscription();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const { website, websiteIsLoading, refetchWebsite, websiteError } = useWebsite();

  const [noWebsite, setNoWebsite] = useState<boolean>(false);

  const { websiteMailingList } = useWebsiteMailingList({ mailingListId: website?.accountId });

  const url = useMemo(
    () =>
      isDevelopment
        ? `https://test-fans.unhurdmusic.com/artist/${website?.route}`
        : `https://fans.unhurdmusic.com/artist/${website?.route}`,
    [website]
  );

  const exportMailingList = useCallback(() => {
    if (!websiteMailingList) return;

    const url = window.URL.createObjectURL(new Blob([websiteMailingList], { type: 'application/zip' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `mailing-list.zip`);
    document.body.appendChild(link);
    link.click();
  }, [websiteMailingList]);

  const deleteFanHub = useCallback(async () => {
    try {
      if (!accountId) return;

      await WebsiteBuilderAPI.deleteFanHub({ accountId });
    } catch (error: unknown) {
      handleApiError({ error, customMessage: 'Failed to delete fan hub', dispatchSnackbar });
    } finally {
      await refetchWebsite();
      setDeleteModalOpen(false);
    }
  }, [accountId, dispatchSnackbar, refetchWebsite]);

  const handleClick = () => {
    if (isSubscribed) {
      params.set(PromoteFlowQueryParams.FAN_HUB, 'true');
      setSearchParams(params);
    } else {
      params.set(PromoteFlowQueryParams.SUBSCRIPTION, 'true');
      setSearchParams(params);
    }
  };

  useEffect(() => {
    if (websiteError) {
      setNoWebsite(true);
    }
  }, [websiteError]);

  return (
    <>
      {websiteIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      <DialogModal
        open={deleteModalOpen}
        output={(confirm) => {
          if (confirm) {
            deleteFanHub();
          }
          setDeleteModalOpen(false);
        }}
        isDelete={true}
        title="Delete Fan Hub"
        content="Are you sure you want to delete your fan hub? This cannot be undone. You will lose access to your mailing list information after deleting so we recommend downloading it first."
      />
      {!websiteIsLoading && website && !websiteError && (
        <div
          className="card-inner w100p mb8 p16 list-item-parent mt10"
          style={{ '--animation-number': 0 } as React.CSSProperties}
        >
          <div className="d-flex list-item campaign flex-wrap">
            <img
              src={website.profileImageUrl ? website.profileImageUrl : '/images/logos/unhurd-logo.png'}
              alt="to-do-img"
            />
            <div className="mt-auto mb-auto text-center">
              <div>
                <CampaignStatus status={website.activationStatus?.activationStatus} />
              </div>
              {website.createdAt && (
                <p className="text-faded small">{format(new Date(website.createdAt), 'dd/MM/yy')}</p>
              )}
            </div>
            <div className="flex-grow mt-auto mb-auto">
              <div className="fs-16 fw-bold text-left">{website.artistName}</div>
              <p
                className="small underline text-faded cursor-pointer"
                onClick={() => {
                  window.open(url, '_blank');
                }}
              >
                {url}
              </p>
            </div>
            <div className="mt-auto mb-auto ml-auto">
              <ButtonComponent className="btn-white" onClick={exportMailingList}>
                Export mailing list
              </ButtonComponent>
            </div>
            <ButtonComponent
              isCustom={true}
              className="mt-auto mb-auto ml-auto"
              onClick={() => {
                handleClick();
              }}
            >
              <CustomIcons className="pt8" name="edit" />
            </ButtonComponent>
            <ButtonComponent
              isCustom={true}
              className="mt-auto mb-auto ml-auto"
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              <CustomIcons className="pt4 material-symbols-outlined text-error" name="delete" />
            </ButtonComponent>
          </div>
        </div>
      )}

      {!websiteIsLoading && (!website || noWebsite) && (
        <div className="text-center card mt16">
          <h5 data-testid="no-pitches-available">No Fan Hub set up</h5>
        </div>
      )}
    </>
  );
};

export default CampaignsFanHub;
